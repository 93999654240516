import { Box, Typography } from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import { OrderSummaryProduct } from '../cart/order-summary-product'
import { CartDivider } from '../cart/cart-divider'
import { IAddress } from '../address/interface/address.interface'
import { AddressOrderPreviewInfo } from '../address/address-order-preview-info'
import { CartSummary } from '../cart/cart-summary'
import { ICartUpdate, emptyCart } from '../../utils/cart.util'
import { BlackButton } from '../product/black-button'
import { ReturnOrder, Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'

export interface IOrderRequestProps extends IRoute {
	setCart: ICartUpdate
}

const Order = (props: IOrderRequestProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { orderId } = useParams()
	const [order, setOrder] = useState<IOrderRequest | null>()
	const [searchParams] = useSearchParams()

	const isNew = searchParams.get('isNew') === 'true'
	useEffect(() => {
		if (isNew) {
			props.setCart(emptyCart())
		}
	}, [isNew])

	useEffect(() => {
		fetch(`${process.env.REACT_APP_CORE_API_URL}/order/${orderId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${Cookies.get('accessToken')}`,
			},
		}).then((response) => {
			response.json().then((data) => {
				setOrder(data)
			})
		})
	}, [])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '0 2% 5%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '1.5rem',
				}}
				gap={2}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flexDirection: props.isMobile ? 'column' : 'row',
						gap: 2,
						width: '100%',
					}}
				>
					<Typography
						sx={{
							width: 'fit-content',
							fontWeight: 'bold',
							fontSize: '1.75rem',
							lineHeight: '2rem',
						}}
					>
						{t(Translations.ORDER_NUMBER)}: {order?.redsysId}
					</Typography>
					{!isNew ? (
						<BlackButton
							text={t(Translations.REQUEST_RETURN)}
							inverted={true}
							onClick={() => navigate('return')}
							width={props.isMobile ? '100%' : '25%'}
						/>
					) : (
						''
					)}
				</Box>
				<Typography
					sx={{
						fontSize: '1.5rem',
						lineHeight: '2rem',
					}}
				>
					{order && t(order.status)}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: props.isMobile ? 'column' : 'row',
					margin: '3rem 0',
				}}
			>
				<Box
					sx={{
						width: props.isMobile ? '100%' : '50%',
					}}
				>
					{order?.payedAt && (
						<>
							<Typography
								sx={{
									fontWeight: 'bold',
									fontSize: '1.5rem',
									lineHeight: '2rem',
								}}
							>
								{t(ReturnOrder.RETURNORDER_BUY_DATE)}
							</Typography>
							<Typography
								sx={{
									fontSize: '1rem',
									lineHeight: '2rem',
								}}
							>
								{new Date(order.payedAt).toLocaleDateString('es-es')}
							</Typography>
						</>
					)}
					{order?.addresses.map((address: IAddress) => (
						<AddressOrderPreviewInfo address={address} />
					))}
				</Box>
				<Box
					sx={{
						width: props.isMobile ? '100%' : '50%',
					}}
				>
					<Typography
						sx={{
							fontWeight: 'bold',
							fontSize: '1.5rem',
							lineHeight: '2rem',
						}}
					>
						{t(ReturnOrder.RETURNORDER_PRODUCTS)}
					</Typography>
					{order?.orderLines.map((line) => (
						<OrderSummaryProduct line={line} />
					))}
				</Box>
			</Box>
			<CartDivider />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'end',
					width: '100%',
				}}
			>
				<CartSummary
					subTotal={{
						value: order?.subtotal || 0,
						valueWithDiscount: order?.subTotalWithDiscount,
						text: t(Translations.SUBTOTAL),
					}}
					total={{
						value: order?.total || 0,
						valueWithDiscount: order?.totalWithDiscount,
						text: t(Translations.TOTAL),
					}}
					delivery={{
						value: order?.shipping || 0,
						text: t(Translations.SHIPPING),
					}}
				/>
			</Box>
		</Box>
	)
}

export default Order
