import {
	Typography,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material'

export const SizeChartTable = () => {
	return (
		<>
			<Typography
				variant='h5'
				sx={{
					mt: '2rem',
				}}
				gutterBottom
			>
				Guía de Tallas - Bebé
			</Typography>

			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Talla</TableCell>
							<TableCell>1m</TableCell>
							<TableCell>3m</TableCell>
							<TableCell>6m</TableCell>
							<TableCell>9m</TableCell>
							<TableCell>12m</TableCell>
							<TableCell>18m</TableCell>
							<TableCell>2a</TableCell>
							<TableCell>3a</TableCell>
							<TableCell>4a</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Estatura</TableCell>
							<TableCell>55</TableCell>
							<TableCell>62</TableCell>
							<TableCell>68</TableCell>
							<TableCell>71</TableCell>
							<TableCell>74</TableCell>
							<TableCell>83</TableCell>
							<TableCell>92</TableCell>
							<TableCell>98</TableCell>
							<TableCell>104</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Pecho</TableCell>
							<TableCell>21</TableCell>
							<TableCell>22</TableCell>
							<TableCell>23</TableCell>
							<TableCell>23.5</TableCell>
							<TableCell>24</TableCell>
							<TableCell>25</TableCell>
							<TableCell>26</TableCell>
							<TableCell>27</TableCell>
							<TableCell>28</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Cintura</TableCell>
							<TableCell></TableCell>
							<TableCell>22</TableCell>
							<TableCell>22.6</TableCell>
							<TableCell>23.2</TableCell>
							<TableCell>23.8</TableCell>
							<TableCell>24.4</TableCell>
							<TableCell>25</TableCell>
							<TableCell>25.6</TableCell>
							<TableCell>26.2</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Cadera</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>28</TableCell>
							<TableCell>29.25</TableCell>
							<TableCell>30.5</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Largo manga</TableCell>
							<TableCell></TableCell>
							<TableCell>20.5</TableCell>
							<TableCell>22</TableCell>
							<TableCell>23.5</TableCell>
							<TableCell>24.5</TableCell>
							<TableCell>27.7</TableCell>
							<TableCell>31</TableCell>
							<TableCell>33</TableCell>
							<TableCell>35</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Largo pantalón niño</TableCell>
							<TableCell></TableCell>
							<TableCell>32</TableCell>
							<TableCell>34</TableCell>
							<TableCell>36.4</TableCell>
							<TableCell>38.6</TableCell>
							<TableCell>45</TableCell>
							<TableCell>51</TableCell>
							<TableCell>55</TableCell>
							<TableCell>59</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Typography
				variant='h5'
				sx={{
					mt: '2rem',
				}}
				gutterBottom
			>
				Guía de Tallas - Niños
			</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Talla</TableCell>
							<TableCell>2a</TableCell>
							<TableCell>3a</TableCell>
							<TableCell>4a</TableCell>
							<TableCell>5a</TableCell>
							<TableCell>6a</TableCell>
							<TableCell>8a</TableCell>
							<TableCell>10a</TableCell>
							<TableCell>12a</TableCell>
							<TableCell>14a</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Estatura</TableCell>
							<TableCell>92</TableCell>
							<TableCell>98</TableCell>
							<TableCell>104</TableCell>
							<TableCell>110</TableCell>
							<TableCell>116</TableCell>
							<TableCell>128</TableCell>
							<TableCell>140</TableCell>
							<TableCell>152</TableCell>
							<TableCell>158</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Pecho</TableCell>
							<TableCell>26</TableCell>
							<TableCell>27</TableCell>
							<TableCell>28</TableCell>
							<TableCell>29</TableCell>
							<TableCell>30</TableCell>
							<TableCell>32</TableCell>
							<TableCell>34.5</TableCell>
							<TableCell>36.5</TableCell>
							<TableCell>38.5</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Cintura</TableCell>
							<TableCell>25</TableCell>
							<TableCell>25.6</TableCell>
							<TableCell>26.2</TableCell>
							<TableCell>26.8</TableCell>
							<TableCell>27.4</TableCell>
							<TableCell>28.75</TableCell>
							<TableCell>30.25</TableCell>
							<TableCell>31</TableCell>
							<TableCell>31.75</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Cadera</TableCell>
							<TableCell>28</TableCell>
							<TableCell>29.25</TableCell>
							<TableCell>30.5</TableCell>
							<TableCell>31.75</TableCell>
							<TableCell>33</TableCell>
							<TableCell>35.5</TableCell>
							<TableCell>38</TableCell>
							<TableCell>40.75</TableCell>
							<TableCell>43.5</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Largo manga</TableCell>
							<TableCell>31</TableCell>
							<TableCell>33</TableCell>
							<TableCell>35</TableCell>
							<TableCell>37.5</TableCell>
							<TableCell>39</TableCell>
							<TableCell>43</TableCell>
							<TableCell>48</TableCell>
							<TableCell>49</TableCell>
							<TableCell>89</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Largo pantalón niño</TableCell>
							<TableCell>1</TableCell>
							<TableCell>2</TableCell>
							<TableCell>3</TableCell>
							<TableCell>4</TableCell>
							<TableCell>5</TableCell>
							<TableCell>6</TableCell>
							<TableCell>7</TableCell>
							<TableCell>8</TableCell>
							<TableCell>9</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
