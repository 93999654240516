import {
	Box,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Typography,
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import { IRoute } from '../../interfaces/route.interface'
import { Translations, ReturnOrder } from '../../translations/translations.enum'
import { OrderSummaryProduct } from '../cart/order-summary-product'
import { BlackButton } from '../product/black-button'
import { RequestManager, isRequestSuccessfull } from '../utils'
import { OrderReturnAddress } from './order-return-address'

export interface IOrderReturnRequestProps extends IRoute {}

const OrderReturn = (props: IOrderReturnRequestProps) => {
	const navigate = useNavigate()
	const { orderId, language } = useParams()
	const [order, setOrder] = useState<IOrderRequest | null>()
	const [returnLines, setReturnLines] = useState<number[]>([])

	const [addressCode, setAddressCode] = useState<string | null>()

	const [errors, setErrors] = useState<any>({
		orderLines: '',
	})

	const [formData, setFormData] = useState<any>({
		termsAccepted: false,
		emailAdvertising: false,
	})

	useEffect(() => {
		retrieveOrder()
	}, [])

	const retrieveOrder = async () => {
		const response = await RequestManager(
			'GET',
			`order/${orderId}/return`,
			true
		)

		if (isRequestSuccessfull(response.status)) {
			setOrder(response.data)
		} else {
			setErrors({
				...errors,
				orderLines: t(ReturnOrder.RETURNORDER_CONTACT_SUPPORT),
			})
		}
	}

	const submit = async () => {
		if (orderLines?.length) {
			const response = await RequestManager('POST', `return-order`, true, {
				orderLineIds: returnLines,
				addressCode,
				redsysId: orderId,
				termsAccepted: formData.termsAccepted,
			})

			if (isRequestSuccessfull(response.status)) {
				const order = response.data
				navigate(`/${language}/account/returns/${order.id}?isNew=true`)
			} else {
				setErrors({
					...errors,
					orderLines: t(ReturnOrder.RETURNORDER_SELECT_TERMS_CONDITIONS),
				})
			}
		} else {
			setErrors({
				...errors,
				orderLines: t(ReturnOrder.RETURNORDER_NO_SELECTED_PRODUCTS),
			})
		}
	}

	const handleClick = (lineId: number) => {
		if (returnLines.includes(lineId)) {
			setReturnLines(returnLines.filter((line) => line !== lineId))
		} else {
			setReturnLines([...returnLines, lineId])
		}
	}

	const handleChange = (event: any) => {
		const { name, value, type, checked } = event.target

		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		})
	}

	const orderLines = order?.orderLines.filter((line) => !line.returnStatus)

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '0 2% 5%',
			}}
		>
			<Typography
				fontSize={'2rem'}
				fontWeight={'bold'}
			>
				Devolución de productos
			</Typography>

			{orderLines?.length ? (
				<>
					<Typography
						fontSize={'1.5rem'}
						sx={{
							marginY: '1rem',
						}}
					>
						Escoja los productos a devolver
					</Typography>

					{orderLines.map((line) => {
						return (
							<Box
								sx={{
									border: returnLines.includes(line.id)
										? '1px solid #000'
										: '1px solid #e0e0e0',
									borderRadius: '5px',
									padding: '1rem',
									my: '1rem',
									cursor: 'pointer',
								}}
								onClick={() => handleClick(line.id)}
							>
								<OrderSummaryProduct
									line={line}
									margin='0px'
								/>
							</Box>
						)
					})}

					<Typography
						fontSize={'1.5rem'}
						sx={{
							fontWeight: 'bold',
							marginY: '1rem',
						}}
					>
						Dirección de recogida
					</Typography>

					<OrderReturnAddress
						isMobile={false}
						setAddressCode={setAddressCode}
					/>

					<Box
						mt='5%'
						gap={2}
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									value='emailAdvertising'
									color='primary'
									name='emailAdvertising'
									checked={formData.emailAdvertising}
									onChange={handleChange}
								/>
							}
							label={t(Translations.CHECK_EMAIL_COMMUNICATIONS)}
						/>
						<FormControlLabel
							control={
								<Checkbox
									value='termsAccepted'
									color='primary'
									name='termsAccepted'
									checked={formData.termsAccepted}
									onChange={handleChange}
								/>
							}
							label={t(ReturnOrder.RETURNORDER_CHECK_TERMS_AND_CONDITIONS)}
						/>
						{errors.termsAccepted && (
							<FormHelperText error>{errors.termsAccepted}</FormHelperText>
						)}
					</Box>

					<Box
						sx={{
							marginTop: '2rem',
						}}
					>
						{errors.orderLines && (
							<FormHelperText error>{errors.orderLines}</FormHelperText>
						)}
						<BlackButton
							text={t(Translations.REQUEST_RETURN)}
							onClick={submit}
							disabled={returnLines.length === 0 || !addressCode}
						/>
					</Box>
				</>
			) : (
				<Typography>All the products have been returned</Typography>
			)}
		</Box>
	)
}

export default OrderReturn
