import { AppBar, Box } from '@mui/material'
import Dropdown from '../dropdown/dropdown'
import { listItemsRight } from '../../dropdown/dropdown-mock'
import { LanguageSelector } from '../language/language-selector'
import LogoSVG from '../../assets/logo-svg'
import { DesktopHeaderBanner } from './header/sections/banner'
import { toTitleCase } from '../../utils'
import { IDropdownLink } from '../../../interfaces/dropdown-link.interface'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Search from '../../search/desktop/search.desktop'
import { LanguageISOCode } from '../../../enums/language-iso-code.enum'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { ICartUpdate } from '../../../utils/cart.util'

interface IDesktopHeader {
	isSticky: boolean
	headerBannerHeight: string
	language: LanguageISOCode
	setLanguage: (language: LanguageISOCode) => void
	isLogged: boolean
	setIsLogged: (isLogged: boolean) => void
	setCart: ICartUpdate
}

export const DesktopHeader = (props: IDesktopHeader) => {
	const { t } = useTranslation()
	const [isSearching, setIsSearching] = useState(false)

	const [parentCategories, setParentCategories] = useState<IDropdownLink[]>([])

	const getCategories = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_CORE_API_URL}/menu?language=${props.language}`
		)

		const result = await response.json()
		setParentCategories(result)
	}

	useEffect(() => {
		getCategories()
	}, [props.language])

	return (
		<AppBar
			position='fixed'
			sx={{
				color: 'primary.main',
				backgroundColor: 'background.default',
				width: '100%',
			}}
		>
			<DesktopHeaderBanner
				headerBannerHeight={props.headerBannerHeight}
				isSticky={props.isSticky}
			/>
			<Box
				sx={{
					flexDirection: 'column',
					padding: '0 18px !important',
					position: 'relative',
				}}
			>
				<LanguageSelector
					isSticky={props.isSticky}
					language={props.language}
					setLanguage={props.setLanguage}
					isDesktop={true}
				/>
				<Link to={`/${props.language}`}>
					<LogoSVG height='60%' />
				</Link>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: props.isSticky ? '30px' : '50px',
					}}
					width={'100%'}
				>
					<Box
						component='nav'
						sx={{ display: 'flex' }}
					>
						{parentCategories?.map((item, index) => (
							<Dropdown
								key={item.id}
								id={item.id}
								label={toTitleCase(t(item.name))}
								children={item.subMenus}
								isLeftSide={true}
								isSticky={props.isSticky}
								language={props.language}
								isCategory={true}
							/>
						))}
					</Box>

					<Box
						component='nav'
						sx={{ display: 'flex' }}
					>
						<Search
							language={props.language}
							isSearching={isSearching}
							setIsSearching={setIsSearching}
							isSticky={props.isSticky}
						/>
						{!isSearching &&
							listItemsRight(
								props.isLogged,
								props.setIsLogged,
								props.setCart,
								props.language
							).reduce((acc, item) => {
								if (
									item.onlyLoggedIn === undefined ||
									!!Cookies.get('accessToken') === item.onlyLoggedIn
								)
									acc.push(
										<Dropdown
											key={item.id}
											id={item.id}
											label={t(item.name)}
											children={item.children as unknown as IDropdownLink[]}
											isLeftSide={false}
											isSticky={props.isSticky}
											language={props.language}
											isCategory={false}
										/>
									)

								return acc
							}, [] as JSX.Element[])}
					</Box>
				</Box>
			</Box>
		</AppBar>
	)
}
