import { IFooterLink } from '../../interfaces/footer-links.interface'
import {
	AccountRoutes,
	Translations,
} from '../../translations/translations.enum'
import { routeMapping } from '../utils/route-mapping'

export const footerNavColumnMockCustomerCare = (
	language: string
): IFooterLink => {
	console.log('...', language)
	return {
		title: Translations.CUSTOMER_CARE,
		url: '#',
		sublinks: [
			{
				title: Translations.CONTACT_US,
				url: `/${language}/${routeMapping.contactUs[language]}`,
			},
			{
				title: AccountRoutes.RETURNS,
				url: `/${language}/${routeMapping.returns[language]}`,
			},
			{
				title: Translations.ORDERING_AND_SHIPPING,
				url: `/${language}/${routeMapping.orderingAndShipping[language]}`,
			},
			{
				title: 'FAQs',
				url: `/${language}/faqs`,
			},
		],
	}
}

export const footerNavColumnMockAboutFinaEjerique = (
	language: string
): IFooterLink => {
	return {
		title: Translations.ABOUT_FE,
		url: '#',
		sublinks: [
			{
				title: Translations.THE_FE_STORY,
				url: `/${language}/${routeMapping.feStory[language]}`,
			},
			{
				title: 'Partners',
				url: `/${language}/partners`,
			},
		],
	}
}
